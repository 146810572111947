import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/pre-trip-inspection",
    name: "Pre-trip-inspection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PreTripInspection.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/post-trip-inspection",
    name: "Post-trip-inspection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PostTripInspection.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/routes",
    name: "Routes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Routes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Test.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/assign",
    name: "Assign",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Assign.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manualassign",
    name: "ManualAssign",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ManualAssign.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pickup",
    name: "Pickup",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Pickup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/extras",
    name: "Extras",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Extras.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manualextras",
    name: "ManualExtras",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ManualExtras.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Logout.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/signature",
    name: "Signature",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Signature.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/swap-routes",
    name: "Swap Routes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SwapRoutes.vue"),
    meta: { requiresAuth: true, isManager: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.console.log(router);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if (!router.app.$token) {
    if (!store.getters.token) {
      window.console.log("Requiere auth");
      next("/login");
      return;
    }
  }
  if (to.matched.some((record) => record.meta.isManager)) {
    //if (!router.app.$token) {
    if (store.getters.driver_type != "Manager") {
      window.console.log("Requiere auth Manager");
      next("/login");
      return;
    }
  }
  next();
});
export default router;
