<template>
  <div class="home py-10">
    <h1 class="text-center">Activities for today</h1>
    <div class="text-center pa-5" v-if="!forToday">There are no activities scheduled for today.</div>
    <v-container v-else>
      <v-row>
        <v-col cols="12" md="6">
          <v-card
            color="cyan darken-1"
            dark
          >
            <v-card-title class="headline">Clock In</v-card-title>

            <v-card-subtitle v-if="!clockIn">You need to Clock In</v-card-subtitle>
            <v-card-subtitle v-else>Clock In completed</v-card-subtitle>

            <v-card-actions v-if="!clockIn">
              <v-btn text @click="clock(9)">Clock In</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            color="amber darken-1"
            dark
          >
            <v-card-title class="headline">Pre Trip Inspection</v-card-title>

            <v-card-subtitle v-if="!preTripInspection">You need to complete the Pre Trip Inspection</v-card-subtitle>
            <v-card-subtitle v-else>Pre Trip Inspection completed</v-card-subtitle>

            <v-card-actions v-if="!preTripInspection">
              <v-btn text to="/pre-trip-inspection">Go to Pre Trip Inspection</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            color="orange darken-1"
            dark
          >
            <v-card-title class="headline">{{ numContainers }} Containers</v-card-title>

            <v-card-subtitle>There are {{ numContainers }} containers to pick up today.</v-card-subtitle>
            <div>
                <ul class="ml-5">
                    <li v-for="(item, index) in containersDesc" :key="'el-' + index" v-text="index + ' (' + item + ')'"></li>
                </ul>
            </div>
            <v-card-actions>
              <v-btn text to="/routes">Go to Routes</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            color="blue darken-1"
            dark
          >
            <v-card-title class="headline">{{ numNotes }} Notes from Office</v-card-title>

            <v-card-subtitle>There are {{ numNotes }} notes from office assigned for today.</v-card-subtitle>
            <div>
                <ul class="ml-5">
                    <li v-for="(item, index) in notes" :key="'not-' + index" v-text="item.businessName + ' (' + item.note + ')'"></li>
                </ul>
            </div>
            <v-card-actions>
              <v-btn text to="/routes">Go to Routes</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            color="green darken-1"
            dark
          >
            <v-card-title class="headline">{{ numRoutes }} Routes</v-card-title>

            <v-card-subtitle>There are {{ numRoutes }} routes to cover today.</v-card-subtitle>

            <v-card-actions>
              <v-btn text to="/routes">Go to Routes</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            color="lime darken-1"
            dark
          >
            <v-card-title class="headline">Post Trip Inspection</v-card-title>

            <v-card-subtitle v-if="!postTripInspection">You need to complete the Post Trip Inspection</v-card-subtitle>
            <v-card-subtitle v-else>Post Trip Inspection completed</v-card-subtitle>

            <v-card-actions v-if="!postTripInspection">
              <v-btn text to="/post-trip-inspection">Go to Post Trip Inspection</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            color="cyan darken-3"
            dark
          >
            <v-card-title class="headline">Clock Out</v-card-title>

            <v-card-subtitle v-if="!clockOut">You need to Clock Out</v-card-subtitle>
            <v-card-subtitle v-else>Clock Out completed</v-card-subtitle>

            <v-card-actions v-if="!clockOut">
              <v-btn text @click="clock(10)">Clock Out</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
    name: 'Home',
    data: () => ({
       forToday: false, 
       numRoutes: 0,
       numContainers: 0,
       numNotes: 0,
       preTripInspection: false,
       postTripInspection: false,
       containersDesc: [],
       notes: [],
       clockIn: false,
       clockOut: false,
       location: [],
    }),
    methods: {
        today: function(){
            var este = this;
            este.$http.post(este.$api + 'today', {
              driver_id: este.$store.getters.driver_id,
              token: este.$store.getters.token,
            }).then(function(response){
              console.log(response);
              if(response.status == 200 && response.data.status == 'ok'){
                  if(response.data.results > 0){
                      console.log(response.data.data);
                      este.forToday = true;
                      este.numRoutes = response.data.data.numRoutes;
                      este.numContainers = response.data.data.numContainers;
                      este.numNotes = response.data.data.numNotes;
                      este.notes = response.data.data.notes;
                      este.preTripInspection = response.data.data.preTripInspection;
                      este.postTripInspection = response.data.data.postTripInspection;
                      este.clockIn = response.data.data.clockIn;
                      este.clockOut = response.data.data.clockOut;
                      este.containersDesc = response.data.data.containersDesc;
                      este.$store.commit('preTripInspection', response.data.data.preTripInspection);
                  } else {
                      este.forToday = false;
                  }
              } else {
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": response.data.msg
                }
                este.$store.commit("msgMuestra", mensaje);
                if('error' in response.data && response.data.error == 'logout'){
                    este.$router.push('/logout');
                }
              }
            }).catch(function(err){
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": "There was an error. Please try again."
              }
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
            });
        },
        clock: function(type){
            var este = this;
            este.$getLocation().then(coordinates => {
                este.location = coordinates;
                este.$http.post(este.$api + 'setClock', {
                  driver_id: este.$store.getters.driver_id,
                  token: este.$store.getters.token,
                  type: type,
                  location: este.location,  
                }).then(function(response){
                  console.log(response);
                  if(response.status == 200 && response.data.status == 'ok'){
                      if(response.data.results > 0){
                          console.log(response.data.data);
                          if(type == 9){
                              este.clockIn = true;
                              este.$store.commit('clockIn', true);
                          } else {
                              este.clockOut = true;
                              este.$store.commit('clockOut', true);
                          }
                      } else {
                          este.forToday = false;
                      }
                  } else {
                    var mensaje = {
                        "color": "red",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
                    if('error' in response.data && response.data.error == 'logout'){
                        este.$router.push('/logout');
                    }
                  }
                }).catch(function(err){
                  var mensaje = {
                      "color": "red",
                      "timeout": 5000,
                      "message": "There was an error. Please try again."
                  }
                  este.$store.commit("msgMuestra", mensaje);
                  window.console.log(err);
                });
          }).catch(function(err){
            var mensaje = {
                "color": "red",
                "timeout": 5000,
                "message": "We couldn't get your location. Please try again."
            }
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          });
        }
    },
    components: {
    },
    mounted: function(){
        if([0, 6].includes(this.$moment().day())){
            this.forToday = false;
        } else {
            // Consulta actividades para el día de hoy
            this.today();
        }
        this.$store.commit('title', 'PRIMA');
    }
}
</script>
